.appTabBar {
  position: fixed;
  bottom: 0;
  left: 0px;
  width: 100%;
  background: var(--color-white);
  z-index: 1;
  box-shadow: var(--shadow-tabbar);
  transition: transform 0.3s ease;
  transform: translateY(0);
}

.appTabBarItems {
  composes: gridSmallWrapper from '@/global/style/Layout.module.css';

  position: relative;
  list-style: none;
  display: grid;
  grid-template-columns: repeat(3, minmax(min-content, 88px));
  justify-content: space-between;
  padding: var(--spacing-2);
}

.activeIndicator::before {
  content: '';
  position: absolute;
  top: 0px;
  height: 4px;
  width: 88px;
  background-color: var(--color-blue);
  transition: left 0.15s ease-out;
}

.abonnemangActive::before {
  left: calc(var(--spacing-2));
}

.betalningarActive::before {
  left: calc(50% - var(--spacing-5) - var(--spacing-05));
}

.kontoinstallningarActive::before {
  left: calc(100% - var(--spacing-10) - var(--spacing-3));
}

.appTabBarItem {
  width: 88px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 8px;
  border-radius: var(--border-radius-sm);
  color: var(--color-grey);
  font-size: var(--font-size-small);
  transition: color 0.3s ease;
  position: relative;
}

.appTabBarItem,
.appTabBarItem span {
  color: var(--color-mine-shaft);
}

.active.appTabBarItem {
  background-color: var(--color-mine-shaft-active);
}

.appTabBarLinkItem,
.appTabBarLinkItem:active,
.appTabBarLinkItem:focus-visible {
  border-radius: var(--border-radius-sm);
}
